export default class InternalServerError500Exception extends Error {
    public name = "InternalServerError500Exception";
    public trace: string;
    public type = 'api error';
    public statusCode = 500;

    constructor(reason: string, extraData: any = {}) {
        super(`Internal Error`);
        this.trace = reason;
    }
}