import {
  UNBXD_POSTS,
  UNBXD_SERVICES_POSTS,
  UNBXD_Filters,
  UNBXD_SELECTED_Filters,
  UNBXD_USPS,
  UNBXD_META_DATA,
  UNBXD_MARKETING_BANNER,
  UNBXD_DEAL,
  UNBXD_LOADING,
  SET_PREV_URL,
  SIMILAR_ITEMS,
  SET_UUID,
  UNBXD_LOAD_MORE,
  UNBXD_RECOMMENDATIONS,
  IS_UNBXD_NEW_USER,
  UNBXD_SPECS,
  GET_FILTERS_SSR,
} from "../types";

const initialState = {
  posts: [],
  servicesPosts: [],
  filters: [],
  filtersSSR: {},
  selectedOptions: [],
  usps: [],
  similarItems: [],
  metaData: {},
  marketingBanner: {},
  dealFacet: {},
  loading: true,
  isCarsPage: false,
  uuid: "",
  isLoadMoreClicked: false,
  recommendations: [],
  isUnbxdNewUser: false,
  specs: {},
};

const unbxdReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UNBXD_POSTS:
      return {
        ...state,
        posts:
          payload.page == 1
            ? payload.products
            : [...state.posts, ...payload.products],
      };
    case UNBXD_SERVICES_POSTS:
      return {
        ...state,
        servicesPosts: payload.products,
      };
    case UNBXD_Filters:
      return {
        ...state,
        filters: payload,
      };
    case GET_FILTERS_SSR:
      return {
        ...state,
        filtersSSR: payload,
      };
    case UNBXD_SELECTED_Filters:
      return {
        ...state,
        selectedOptions: payload,
      };
    case UNBXD_USPS:
      return {
        ...state,
        usps: payload,
      };

    case UNBXD_META_DATA:
      return {
        ...state,
        metaData: {
          seoData: payload.seoData,
          totalPages: payload.totalPages,
          productsCount: payload.productsCount,
          title: payload.title,
          category_path: payload.category_path,
          requestId: payload.request_id,
          metaTags: payload.metaTags,
        },
      };

    case UNBXD_MARKETING_BANNER:
      return {
        ...state,
        marketingBanner: payload,
      };

    case UNBXD_DEAL:
      return {
        ...state,
        dealFacet: payload,
      };

    case UNBXD_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SIMILAR_ITEMS:
      return {
        ...state,
        similarItems: payload,
      };

    case SET_PREV_URL:
      return {
        ...state,
        preUrl: payload,
      };

    case SET_UUID:
      return {
        ...state,
        uuid: payload,
      };

    case UNBXD_LOAD_MORE:
      return {
        ...state,
        isLoadMoreClicked: payload,
      };

    case UNBXD_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: payload,
      };

    case IS_UNBXD_NEW_USER:
      return {
        ...state,
        isUnbxdNewUser: payload,
      };

    case UNBXD_SPECS:
      return {
        ...state,
        specs: payload,
      };

    default:
      return state;
  }
};

export default unbxdReducer;
