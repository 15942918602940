import {I18N_CDN, SELECTED_ENV} from "@globalConfig";
import {TypeI18nNamespaces} from "@/i18n/types";

export const getI18nFilePath = (lang: string, namespace: TypeI18nNamespaces) => {

    const env = __IS_LOCAL_ENV__ ? "qa" : SELECTED_ENV;
    return I18N_CDN
        .replaceAll("{env}", env)
        .replace("{lang}", lang)
        .replace("{namespace}", namespace);

}