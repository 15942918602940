import fetcher from "@/common/axiosInstance";
import NotFound404Exception from "@/exceptions/NotFound404Exception";
import InternalServerError500Exception from "@/exceptions/InternalServerError500Exception";
import { ISearchResults } from "../../../types/models/search";

type TypeIncludes = ("usps" | "meta_tags")[];

const pendingRequests: { [key: string]: Promise<ISearchResults> } = {};

async function fetchSearchResults(req: any, axiosConfig: any): Promise<ISearchResults> {

    return fetcher(req).get(`/search/index`, axiosConfig).then(async res => {
        if (res?.data?.code !== 200) {
            throw new InternalServerError500Exception("api__doSearch", {
                url: "search/index",
                data: axiosConfig.params,
            });
        }
        if (req?.query?.page && +req.query.page > res.data.data.total_pages) {
            throw new NotFound404Exception("api__doSearch_page_exceeds_total_pages");
        }

        return res?.data?.data || ({} as ISearchResults);
    });
}

export const api__doSearch = async (
    req: any,
    page = 1,
    data = {},
    includes: TypeIncludes = []
): Promise<ISearchResults> => {

    if (req?.query?.page && (+req.query.page < 1 || isNaN(+req.query.page))) {
        throw new NotFound404Exception("api__doSearch_wrong_validation");
    }

    const requestData = {
        includes: includes.join(","),
        search_data: JSON.stringify(data || {}),
    };

    const cacheKey = JSON.stringify(requestData);

    if (__IS_CSR__ && pendingRequests[cacheKey]) {
        return await pendingRequests[cacheKey];
    }

    const axiosConfig = {
        params: requestData,
    };

    const requestPromise = fetchSearchResults(req, axiosConfig).finally(() => {
        if (__IS_CSR__) {
            delete pendingRequests[cacheKey];
        }
    });

    if (__IS_CSR__) {
        pendingRequests[cacheKey] = requestPromise;
    }

    return await requestPromise;
};
