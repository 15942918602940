export const SEARCH_FILTER_ALL = null;
export const SEARCH_FILTER_NEW = 1;
export const SEARCH_FILTER_USED = 0;
export const LOGIN_RESOURCE = "LOGIN_SOURCE";
export const FAV_RESOURCE = "FAV_RESOURCE";
export const COMMENT_RESOURCE = "COMMENT_RESOURCE";
export const SECTION_TYPE_DEAL_NEW = 2;
export const SECTION_TYPE_DEAL_USED = 3;

//home page
export const HOME_META_TITLE = "سيارات للبيع في السعودية | موقع سيارة";
export const EN_HOME_META_TITLE = "Cars for Sale in Saudi Arabia | Syarah";
export const HOME_META_DESCRIPTION =
  "اشتري سيارتك بسهولة و من أي مكان في السعودية. أوسع تشكيلة من السيارات الجديدة والمستعملة. سياراتنا المستعملة مفحوصة ومضمونة جربها وإن ما جازتلك رجعها.";
export const EN_HOME_META_DESCRIPTION =
  "Buy your car with confidence anywhere in Saudi Arabia. Wide selection of new and used cars. Our used cars are inspected & guaranteed with 10-day refund guarantee.";

//new-cars
export const NEW_CARS_META_TITLE =
  "سيارات جديدة للبيع في السعودية | موقع سيارة";
export const EN_NEW_CARS_META_TITLE =
  "New Cars for Sale in Saudi Arabia | Syarah";
export const NEW_CARS_META_DESCRIPTION =
  "اشتري سيارتك بسهولة و من أي مكان في السعودية. أوسع تشكيلة من السيارات الجديدة والمستعملة. سياراتنا المستعملة مفحوصة ومضمونة جربها وإن ما جازتلك رجعها.";
export const EN_NEW_CARS_META_DESCRIPTION =
  "Buy your car with confidence anywhere in Saudi Arabia. Wide selection of new and used cars. Our used cars are inspected & guaranteed with 10-day refund guarantee.";

//classified-cars
export const CLASSIFIED_CARS_META_TITLE =
  "حراج سيارات للبيع في السعودية | موقع سيارة";
export const EN_CLASSIFIED_CARS_META_TITLE =
  "Haraj Cars for Sale in Saudi Arabia | Syarah";
export const CLASSIFIED_CARS_META_DESCRIPTION =
  "اشتري سيارتك اونلاين بسهولة و من أي مكان في السعودية. تصفح العديد من السيارات الجديدة والمستعملة. جميع سياراتنا المستعملة مفحوصة ومضمونة ونوصلها لين عندك.";
export const EN_CLASSIFIED_CARS_META_DESCRIPTION =
  "Buy your car with confidence anywhere in Saudi Arabia. Wide selection of new and used cars. Our used cars are inspected & guaranteed with 10-day refund guarantee.";

//used-cars
export const USED_CARS_META_TITLE =
  "سيارات مستعملة مضمونة للبيع في السعودية | موقع سيارة";
export const EN_USED_CARS_META_TITLE =
  "Used Guarenteed Cars for Sale in Saudi Arabia | Syarah";
export const USED_CARS_META_DESCRIPTION =
  "اشتري سيارتك المستعملة من أي مكان في السعودية. سياراتنا المستعملة مفحوصة لأكثر من 100 نقطة ومضمونة لمدة سنة نوصلها لين عندك تجربها 10 أيام وإن ما جازتلك رجعها.";
export const EN_USED_CARS_META_DESCRIPTION =
  "Buy used cars with confidence anywhere in Saudi Arabia. Our used cars are inspected for over 100 points. Comes with 1-Year warranty & 10-day refund guarantee.";

//new
export const NEW_META_TITLE = "حراج السيارات الجديدة في السعودية | موقع سيارة";
export const NEW_META_DESCRIPTION =
  "شاهد سيارات جديدة للبيع من حراج موقع سيارة، حراج السيارات يعرض السيارات  الممولة ( تقسيط ) و الأقل سعرا للبيع في السعودية من سيارة اونلاين ";

//used
export const USED_META_TITLE =
  "حراج السيارات المستعملة في السعودية | موقع سيارة";
export const USED_META_DESCRIPTION =
  " شاهد سيارات مستعملة للبيع من حراج موقع سيارة، حراج السيارات يعرض سيارات مفحوصة و مضمونة للبيع في السعودية من سيارة اونلاين";

export const OEM_DRIVE_RESOURCE = "OEM_DRIVE_RESOURCE";

export const CLASSIFIED = 0;
export const IS_ONLINE = 1;

export const CONDITION_USED = 0;
export const CONDITION_NEW = 1;
