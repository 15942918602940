
export let IsDom = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export let Ctr = false;


export const SetCtr = (val) => {
  Ctr = val;
};

export const getNumberWithOutPlus = (num) => {
  return num?.replace("+966", "");
};

export const scrollToElement = (element, scrollOffset) => {
  const yOffset = scrollOffset;
  const el = element;
  const y = el?.getBoundingClientRect()?.top + window.pageYOffset + yOffset;
  window.scrollTo({
    top: y,
    //  behavior: "smooth"
  });
};

export function addSwipeEvents(objToSwipe, leftFunction, RightFunction) {
  if (objToSwipe) {
    objToSwipe.addEventListener("touchstart", handleTouchStart, {
      passive: true,
    });
    objToSwipe.addEventListener("touchmove", handleTouchMove, {
      passive: true,
    });
    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          document.querySelector("body").dir == "rtl"
            ? leftFunction()
            : RightFunction();
        } else {
          /* right swipe */
          //RightFunction();
          document.querySelector("body").dir == "rtl"
            ? RightFunction()
            : leftFunction();
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
  }
}

export const AddSwipeUp = (objToSwipe, functions) => {
  if (objToSwipe) {
    objToSwipe.addEventListener("touchstart", handleTouchStart, false);
    objToSwipe.addEventListener("touchmove", handleTouchMove, false);
    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (yDiff > 0) {
        /* up swipe */
        functions();
      }

      /* reset values */
      xDown = null;
      yDown = null;
    }
  }
};


export const generateUniqueNumber = (min = 100, max = 10000) => {
  return Date.now() + "" + Math.floor(Math.random() * (max - min)) + min;
};

export const range = (min, max) =>
  Array.from({ length: max - min + 1 }, (_, i) => min + i);

export function getDateThreeMonthsFromNow() {
  const currentDate = new Date();

  // Add three months to the current date
  currentDate.setMonth(currentDate.getMonth() + 3);

  // Format the date
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  // Return the formatted date
  return `${year}-${month}-${day}`;
}

export function normalizePrice(str) {
  if (typeof str !== "string") return null;

  // Replace commas with periods
  const formattedStr = str.replaceAll(",", "");

  // Parse the string as a floating-point number
  const number = parseFloat(formattedStr);

  return number;
}

export function parseJsonSafely(jsonString,defaultValue=null) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultValue;
  }
}
export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return null;

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function extractHashToObject(url) {
    const hashIndex = url.indexOf("#");
    if (hashIndex === -1) {
        return {};
    }

    const hashFragment = url.slice(hashIndex + 1);
    const hashParams = new URLSearchParams(hashFragment);
    const hashObject = {};

    for (const [key, value] of hashParams.entries()) {
        hashObject[key] = value;
    }

    return hashObject;
}


export function generateYearsRangeList() {
  const endYear = (new Date().getFullYear()) + 1;
  const startYear = 2020;

  const yearsList = [];
  for (let x = startYear; x <= endYear; x++){
    yearsList.push(x);
  }

  return yearsList;
}

export function extractPostId(text: string | null): number | null {
  if (!text) return null;

  // Split the text by non-word characters
  const parts = decodeURIComponent(text).split(/[\W_]+/);

  let maxPostId: number | null = null;

  // Iterate through parts to find the maximum valid post ID
  for (let part of parts) {
    // Convert part to a number
    const num = Number(part);

    // Check if it's a valid number and not a year in the range 2006-2024
    if (!isNaN(num) && (num < 2006 || num > 2024)) {
      if (maxPostId === null || num > maxPostId) {
        maxPostId = num;
      }
    }
  }

  // Return the maximum valid post ID found, or null if none found
  return maxPostId;
}
