import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {getI18nFilePath} from "./helpers";
import namespaces from "./namespaces";



const options = {
  fallbackLng: "ar",
  supportedLngs: ["en", "ar"],
  load: "languageOnly",
  ns: namespaces,
  defaultNS: "web_common",
  backend: {
    loadPath: getI18nFilePath,
    crossDomain: true,
  },
  saveMissing: false,
  debug: false,
  react: {
    useSuspense: false,
  },
  detection: {
    order: ["path"],
    lookupFromPathIndex: 0,
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
    format: (value, format, lng) => {
      if (format === "uppercase") return value.toUpperCase();
      return value;
    },
  },
  wait: process && !process.release,
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) i18n.use(LanguageDetector).init(options);

export default i18n;
