import {apiUrl, CLIENT_SECRET, serverUrl} from "@globalConfig";
import axios from "axios";
import cookieClient from "react-cookies";
import cookieServer from "cookie";
import {loadSentryClient} from "@/utils/asyncHelpers";

const extractTokenFromCookie = (req) => {
  if (req) {
    const userCookie = req.headers.cookie
      ? cookieServer.parse(req.headers.cookie)
      : null;
    if (userCookie) {
      if (userCookie.accessToken) {
        return userCookie.accessToken;
      }
    }
  }
  return null;
};

export const extractUUIDFromCookie = (req) => {
  if (req) {
    const userCookie = req.headers.cookie
      ? cookieServer.parse(req.headers.cookie)
      : null;

    if (userCookie) {
      if (userCookie.UUID) {
        return userCookie.UUID;
      }
    }
  }
  return null;
};

const invalidateUser = () => {
  if (typeof window !== "undefined") {
    localStorage.setItem("user", null);
    window.location.href = "/user/signup";
  }
};

const checkResponseCode = (response, code) =>
  response?.data?.code === code || response?.status === code;

const is404 = (response) => checkResponseCode(response, 404);
const is230 = (response) => checkResponseCode(response, 230);
const isUnauthorized = (response) =>
  [401, 403].some((code) => checkResponseCode(response, code));
const isNotSuccess = (response) => {
  return (
    response?.status >= 400 ||
    response?.data?.code >= 400 ||
    response?.data?.success === false
  );
};

/**
 *
 * @param {import('express').Request} [req] - The optional Express request object
 * @param isJsonContentType
 * @returns {import('axios').AxiosInstance}
 */
const createFetcher = (req = null, isJsonContentType = false) => {
  if (req && req.axiosInstance) {
    return req.axiosInstance;
  }

  const token = extractTokenFromCookie(req);

  const UUID = extractUUIDFromCookie(req) || req?.UUID;

  let baseURL = `${apiUrl}${req ? `/${req.language}` : ""}`;
  // get Ip address from express request, or cloudflare headers
  const remoteIp =
    req?.headers?.["cf-connecting-ip"] ||
    req?.headers?.["x-forwarded-for"] ||
    req?.headers?.["x-real-ip"] ||
    null;
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      ...(!token ? "" : { Authorization: `Bearer ${token}` }),
      token: CLIENT_SECRET,
      "Content-Type": isJsonContentType ? "application/json" : "application/x-www-form-urlencoded",
      Accept: "application/json",
      "user-id": UUID,
      device: "web",
      "Accept-EnhancedStatusCodes": 1,
      ...(remoteIp ? { "x-secret-forwarded-ip": remoteIp } : {}),
    },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      console.log("-> Fetch.url", config.baseURL, config.url);
      config.headers["user-id"] = cookieClient.load("UUID") || "";
      if (__IS_CSR__) {
        const urlLang = window.location.pathname.match(/^(\/en)/) ? "en" : "ar";
        const isLocalhost = window.location.hostname.includes("localhost");
        config.baseURL = `${apiUrl}/${urlLang}`;

        if (__IS_LOCAL_ENV__ && isLocalhost) {
          config.baseURL = `/local-proxy/${config.baseURL}`;
        }
        const token = cookieClient.load("accessToken") || "";
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (isNotSuccess(response)) {
        // Log the full response to Sentry
        const errorMessage = `Non-200 application code received: ${
          response?.config?.url || "URL"
        }`;

        loadSentryClient(req).then((senInstance) => {
          if (!is230(response) && !is404(response) && senInstance) {
            senInstance.withScope((scope) => {
              scope.setExtra(
                "api",
                `${response?.config?.method || "METHOD"} ${
                  response?.config?.url || "URL"
                }`
              );
              scope.setExtra("body", response?.config?.data);
              scope.setExtra("request_headers", response?.config?.headers);
              scope.setExtra("response_headers", response?.headers);
              scope.setExtra("response_body", response?.data || {});
              scope.setExtra("status_code", response?.data?.code);
              scope.setExtra("http_status_code", response?.status);
              scope.setExtra(
                "validation_errors",
                response?.data?.validation_errors
              );
              scope.setExtra("error", response?.data?.error);
              senInstance.captureException(new Error(errorMessage));
            });
          }
        });
      }

      if (isUnauthorized(response)) {
        invalidateUser();
      }
      return response;
    },
    function (error) {
      console.error("-> error", error);
      if (isUnauthorized(error?.response)) {
        invalidateUser();
      }
      if (req?.Sentry && !is404(error?.response)) {
        // Create a customized error message to capture
        let errorMessage = "Axios request failed: ";

        if (error?.response) {
          errorMessage += `Status code: ${error?.response?.status}, URL: ${
            error?.response?.config?.url
          }, Params: ${JSON.stringify(
            error?.response?.config?.params
          )}, Data: ${JSON.stringify(error?.response?.data)}`;
        } else if (error?.request) {
          errorMessage += `Request made but no response received, URL: ${
            error?.request?.url
          }, Params: ${JSON.stringify(error?.request?.params)}`;
        } else {
          errorMessage += error?.message || "Axios Error: unknown";
        }

        // Capture the exception in Sentry
        req.Sentry.captureException(new Error(errorMessage));
      }
      // on server side, we need to reject the promise
      if (req) {
        return Promise.reject(error);
      }
      return Promise.resolve(error);
    }
  );

  if (req && req?.axiosInstance) {
    req.axiosInstance = axiosInstance;
  }
  return axiosInstance;
};



export default createFetcher;
