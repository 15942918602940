export default class NotFound404Exception extends Error {
    public name = "NotFound404Exception";
    public trace: string;
    public type= 'api error';
    public statusCode= 404;

    constructor( reason: string ) {
        super(`Not Found Page`);
        this.trace = reason;
    }
}