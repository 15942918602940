export const groupByParentId = (
  makes,
  models,
  extins,
  filterField,
  optionsList
) => {
  const makesValues = makes?.values || [];
  const modelsValues = models?.values || [];
  const extinsValues = extins?.values || [];

  const isModel = filterField === "model_id";
  const isExtins = filterField === "trim_id";
  const parentIds = [];

  if (isModel) {
    modelsValues.forEach((model) => {
      const modelParent = makesValues.find(
        (parent) => parent?.id === model.parent_ids[0]
      );

      if (!modelParent) return;

      if (!parentIds.includes(modelParent?.id)) parentIds.push(modelParent.id);
    });

    return parentIds.map((id) => {
      const parent = makesValues.find((parent) => parent.id === id);
      const children = modelsValues.filter(
        (child) => child.parent_ids[0] === id
      );

      return {
        ...parent,
        values: children,
      };
    });
  }

  if (isExtins) {
    extinsValues.forEach((ext) => {
      const extParent = modelsValues.find(
        (parent) => parent.id === ext.parent_ids[0]
      );

      if (!extParent) return;

      if (!parentIds.includes(extParent.id)) parentIds.push(extParent.id);
    });

    return parentIds.map((id) => {
      const model = modelsValues.find((parent) => parent.id === id);
      const make = makesValues.find(
        (parent) => parent.id === model.parent_ids[0]
      );
      const children = extinsValues.filter(
        (child) => child.parent_ids[0] === id
      );
if(!make || !model){
      console.log("make", {make, model, extinsValues, modelsValues, makesValues});
}
      return {
        ...model,
        name: `${make.name} ${model.name}`,
        values: children,
      };
    });
  }

  if (optionsList) {
    const values = optionsList.values;

    const parents = values.map((value) => value.parent);
    const uniqueItems = new Set();

    const resultArray = parents.filter((item) => {
      const itemString = JSON.stringify(item);
      const isUnique = !uniqueItems.has(itemString);
      if (isUnique) {
        uniqueItems.add(itemString);
      }
      return isUnique;
    });

    return resultArray.map((parent) => {
      const newValues = values.filter(
        (value) => value.parent.parent_id === parent.parent_id
      );

      return {
        ...parent,
        name: parent.name,
        values: newValues,
      };
    });
  }
};

export const mapFacets = (facets) => {
  const finalFacets = [];
  const makes = facets.find((facet) => facet?.filterField === "make_id");
  const models = facets.find((facet) => facet?.filterField === "model_id");
  const extensions = facets.find((facet) => facet?.filterField === "trim_id");

  facets.forEach((facet) => {
    if (facet?.filterField === "model_id") {
      finalFacets.push({
        ...facet,
        values: groupByParentId(makes, models, extensions, facet?.filterField),
      });
    } else if (facet?.filterField === "trim_id") {
      finalFacets.push({
        ...facet,

        values: groupByParentId(makes, models, extensions, facet?.filterField),
      });
    } else if (facet?.filterField === "options_list") {
      finalFacets.push({
        ...facet,
        values: groupByParentId(
          makes,
          models,
          extensions,
          facet?.filterField,
          facet
        ),
      });
    } else {
      finalFacets.push(facet);
    }
  });

  return finalFacets;
};

export function mapSelected(selected) {
  const finalArray = [];
  const makes = selected.filter((child) => child?.filterField === "make_id");
  const models = selected.filter((child) => child?.filterField === "model_id");
  const extins = selected.filter((child) => child?.filterField === "trim_id");
  const otherFacets = selected.filter(
    (child) =>
      child?.filterField !== "make_id" &&
      child?.filterField !== "model_id" &&
      child?.filterField !== "trim_id"
  );

  extins.forEach((ext) => {
    const extMakeId = ext.parent_ids[1]; // ! will be parent_idss
    const extModelId = ext.parent_ids[0];
    const model = models.find((mod) => mod.id === extModelId);
    const make = makes.find((mak) => mak.id === extMakeId);

    // console.log("make", make);
    // console.log("model", model);

    if (
      !finalArray.some(
        (item) => item.id === make?.id && item.filterField === make?.filterField
      )
    )
      finalArray.push(make);

    if (
      !finalArray.some(
        (item) =>
          item.id === model?.id && item?.filterField === model?.filterField
      )
    )
      finalArray.push(model);

    finalArray.push(ext);
  });

  models.forEach((model) => {
    const modelMakeId = model.parent_ids[0];
    const make = makes.find((mak) => mak.id === modelMakeId);

    if (
      !finalArray.some(
        (item) => item.id === make.id && item?.filterField === make?.filterField
      )
    )
      finalArray.push(make);
    if (
      !finalArray.some(
        (item) =>
          item.id === model.id && item?.filterField === model?.filterField
      )
    )
      finalArray.push(model);
  });

  makes.forEach((make) => {
    if (
      !finalArray.some(
        (item) =>
          item &&
          make &&
          item.id === make.id &&
          item.filterField === make.filterField
      )
    )
      finalArray.push(make);
  });

  return [...finalArray, ...otherFacets];
}

export const isRangeFilter = (filterName) => {
  const rangeFilters = ["year", "odometer", "sellingprice"];

  return rangeFilters.includes(filterName);
};

export const buildParamsObject = (params) => {
  const paramsObject = {};

  for (const [key, value] of params.entries()) {
    if (key === "deal" && value) {
      paramsObject["deal_id"] = value;
      continue;
    }
    paramsObject[key] = value;
  }

  return paramsObject;
};

export const prepareDeletedFilters = (filter, selectedOptions) => {
  const isRange = isRangeFilter(filter?.filterField);
  const IsMake = filter?.filterField === "make_id";
  const IsModel = filter?.filterField === "model_id";

  if (isRange) {
    return selectedOptions.filter(
      (option) => option?.filterField !== filter?.filterField
    );
  }

  if (IsMake) {
    return selectedOptions.filter((option) => {
      const isDeletedMake =
        option?.filterField === "make_id" && option.id === filter.id;

      const isMakeModel =
        option?.filterField === "model_id" &&
        filter.id === option.parent_ids[0];

      const isMakeTrim =
        option?.filterField === "trim_id" && filter.id === option.parent_ids[1];

      return !isDeletedMake && !isMakeModel && !isMakeTrim;
    });
  }

  if (IsModel) {
    return selectedOptions.filter((option) => {
      const isDeletedModel =
        option?.filterField === "model_id" && option.id === filter.id;

      const isModelTrim =
        option?.filterField === "trim_id" && filter.id === option.parent_ids[0];

      return !isDeletedModel && !isModelTrim;
    });
  }

  return selectedOptions.filter((option) => {
    const isDeletedFilter =
      option?.filterField === filter?.filterField && option.id === filter.id;

    return !isDeletedFilter;
  });
};

export function validateFilters(arr) {
  // Create a set of unique parent IDs in the array
  const makesIds = new Set(
    arr.filter((item) => item?.filterField === "make_id").map((item) => item.id)
  );
  const modelsIds = new Set(
    arr
      .filter((item) => item?.filterField === "model_id")
      .map((item) => item.id)
  );

  // Filter the array to include only objects with valid parent IDs
  const filteredArray = arr.filter((item) => {
    if (item?.filterField === "model_id") {
      return makesIds.has(item.parent_ids[0]);
    }
    if (item?.filterField === "trim_id") {
      return makesIds.has(item.parent_ids[1]);
    }
    return true;
  });

  return filteredArray;
}


export const validateModelsTrims = (filters) => {
  if (filters?.model_id && !filters?.make_id) {
    return false;
  }

  if (filters?.trim_id && (!filters?.model_id || !filters?.make_id)) {
    return false;
  }

  return true;
};
