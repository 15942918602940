import {
  GET_POST_SHARE_LINK,
  ADD_POST_TO_FAVORITE,
  DELETE_POST_FAVORITE,
  ADD_COMMENT_TO_POST,
  ABUSE_POST_COMMENT,
  ABUSE_POST,
  RESET_POST_DATA,
  REPOST,
  GET_ADD_POST_PARAMS,
  GET_ADD_POST_MAKE_MODELS,
  GET_NEW_POST_VIEW_DATA,
  UPDATE_POST_VIEW,
} from "../types";
const initialState = {
  postData: {
    images: [],
  },
  newPostData: {},
  shareLink: "",
  favoriteId: null,
  comment: null,
  metaTags: [],
  abuseComment: null,
  abusePost: null,
  repostResponse: null,
  params: {},
  models: null,
};
const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NEW_POST_VIEW_DATA:
      return {
        ...state,
        newPostData: { ...state.newPostData, ...payload },
        metaTags: payload.meta,
      };
    case GET_POST_SHARE_LINK:
      return {
        ...state,
        shareLink: payload.text,
      };
    case ADD_POST_TO_FAVORITE:
    case DELETE_POST_FAVORITE:
      return {
        ...state,
        favoriteId: payload.favorite_id,
      };
    case ADD_COMMENT_TO_POST:
      return {
        ...state,
        comment: payload,
      };
    case ABUSE_POST_COMMENT:
      return {
        ...state,
        abuseComment: payload,
      };
    case ABUSE_POST:
      return {
        ...state,
        abusePost: payload,
      };
    case RESET_POST_DATA:
      return {
        ...state,
        postData: initialState.postData,
      };
    case REPOST:
      return {
        ...state,
        repostResponse: payload,
      };
    case GET_ADD_POST_PARAMS:
      return {
        ...state,
        params: payload,
      };
    case GET_ADD_POST_PARAMS:
      return {
        ...state,
        params: payload,
      };
    case GET_ADD_POST_MAKE_MODELS:
      return {
        ...state,
        models: payload.models,
      };
    case UPDATE_POST_VIEW:
      return {
        ...state,
        newPostData: {
          ...state.newPostData,
          details: {
            ...state.newPostData.details,
            isFavorite: !state.newPostData.details.isFavorite,
          },
        },
      };
    default:
      return state;
  }
};
export default postReducer;