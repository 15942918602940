import loadable from "@loadable/component";
import Loader from "../components/sharedComponents/Loader";

const fallback = {
  fallback: <Loader />,
};

export const Home = loadable(() => import("../pages/home/home"), fallback);

export const AuthRedirect = loadable(
  () => import("../pages/auth/auth-redirect")
);

export const MobileOffers = loadable(
  () => import("../pages/mobile-offers/MobileOffers"),
  fallback
);

export const PostView = loadable(
  () => import("../pages/post/PostViewNew"),
  fallback
);

export const SimilarPosts = loadable(
  () => import("../pages/similar-posts-page/SimilarPostsPage"),
  fallback
);

export const page404 = loadable(
  () => import("../pages/page404/page404"),
  fallback
);

export const UserLogin = loadable(
  () => import("../pages/auth/user/UserLogin"),
  fallback
);

export const SignUp = loadable(
  () => import("../pages/auth/user/UserSignUp"),
  fallback
);

export const Affiliate = loadable(
  () => import("../pages/affiliate/Affiliate"),
  fallback
);

export const Faq = loadable(() => import("../pages/faqs/Faq"), fallback);

export const Reviews = loadable(
  () => import("../pages/reviews/Reviews"),
  fallback
);

export const HelpPage = loadable(
  () => import("../pages/help/HelpPage"),
  fallback
);

export const CarPurchasePrivacyPolicy = loadable(
  () => import("../pages/privacy-policy/CarPurchasePrivacyPolicy"),
  fallback
);

export const CarPurchaseTermsConditions = loadable(
  () =>
    import(
      "../pages/terms/CarPurchaseTermsConditions/CarPurchaseTermsConditions"
    ),
  fallback
);

export const AboutUs = loadable(
  () => import("../pages/about-us/AboutUs"),
  fallback
);
export const Services = loadable(
  () => import("../pages/services/Services"),
  fallback
);

export const Contact = loadable(
  () => import("../pages/contact/Contact"),
  fallback
);

export const TrackingLanding = loadable(
  () => import("../pages/tracking/trackingLanding/TrackingLanding"),
  fallback
);

export const TrackingFinanceOrders = loadable(
  () =>
    import(
      "../pages/tracking/trackingFinance/TrackingFinanceOrders/TrackingFinanceOrders"
    ),
  fallback
);
export const TrackingCheckoutOrders = loadable(
  () =>
    import(
      "../pages/tracking/trackingCheckout/TrackingCheckoutOrders/TrackingCheckoutOrders"
    ),
  fallback
);

export const TrackingFinanceDetails = loadable(
  () =>
    import(
      "../pages/tracking/trackingFinance/TrackingFinanceDetails/TrackingFinanceDetails"
    ),
  fallback
);

export const TrackingCheckoutDetails = loadable(
  () =>
    import(
      "../pages/tracking/trackingCheckout/TrackingCheckoutDetails/TrackingCheckoutDetails"
    ),
  fallback
);
export const TrackingCheckoutStepDetails = loadable(
  () =>
    import(
      "../pages/tracking/trackingCheckout/TrackingCheckoutStepDetails/TrackingCheckoutStepDetails"
    ),
  fallback
);
export const TrackingCheckoutViewAllOfferedDoc = loadable(
  () =>
    import(
      "../pages/tracking/trackingCheckout/TrackingCheckoutViewAllOfferedDoc/TrackingCheckoutViewAllOfferedDoc.jsx"
    ),
  fallback
);

export const Referral = loadable(
  () => import("../pages/referral/Referral"),
  fallback
);

export const ReferralList = loadable(
  () => import("../pages/referral-list/ReferralList"),
  fallback
);

export const CarInfo = loadable(
  () => import("../pages/car-info/CarInfo"),
  fallback
);

export const StepOne = loadable(
  () => import("../pages/checkout/stepOne/StepOne"),
  fallback
);

export const Checkoutsuccess = loadable(
  () => import("../pages/checkout/checkoutSuccess"),
  fallback
);

export const Finance = loadable(
  () => import("../pages/finance/Finance"),
  fallback
);

export const FinanceFormPage = loadable(
  () => import("../pages/finance-checkout/FinanceFormPage"),
  fallback
);

export const FinanceFormPageFree = loadable(
  () => import("../pages/finance-checkout/FreeFinanceFormPage"),
  fallback
);

export const FinanceOptionalRefund = loadable(
  () => import("../pages/finance-optional-refund/FinanceOptionalRefund"),
  fallback
);
export const FinancePayment = loadable(
  () => import("../pages/finance-checkout/FinanceCheckoutRenderer.jsx"),
  fallback
);

export const PaymentProccessing = loadable(
  () => import("../pages/finance-checkout/PaymentProccessing"),
  fallback
);

export const PaymentProccessingCash = loadable(
  () => import("../pages/finance-checkout/PaymentProccessingCash"),
  fallback
);

export const FinanceCheckoutsuccess = loadable(
  () => import("../pages/finance-checkout/FinanceCheckoutsuccess"),
  fallback
);

export const FinanceRejectedPage = loadable(
  () => import("../pages/finance-checkout/FinanceRejectedPage"),
  fallback
);

export const Payment = loadable(
  () => import("../pages/offline-payment/Payment"),
  fallback
);

export const RemainingPayment = loadable(
  () => import("../pages/RemainingPayment/RemainingPayment"),
  fallback
);

export const FinanceRemainingPayment = loadable(
  () =>
    import(
      "../pages/RemainingPayment/FinanceRemainingPayment/FinanceRemainingPayment"
    ),
  fallback
);

export const SellUs = loadable(
  () => import("../pages/sell-us/SellUs"),
  fallback
);
export const Survey = loadable(
  () => import("../pages/survey/Survey"),
  fallback
);
export const UserAccount = loadable(
  () => import("../pages/user/UserAccount"),
  fallback
);
export const UserFavorite = loadable(
  () => import("../pages/user/UserFavorite"),
  fallback
);
export const UserInfo = loadable(
  () => import("../pages/user/UserInfo"),
  fallback
);
export const UserEditName = loadable(
  () => import("../pages/user/UserEditName"),
  fallback
);

export const UserEditMobile = loadable(
  () => import("../pages/user/UserEditMobile"),
  fallback
);

export const PreCheckout = loadable(
  () => import("../pages/checkout/PreCheckout/PreCheckout"),
  fallback
);

export const healthcheck = loadable(
  () => import("../pages/healthcheck/healthcheck")
);

export const ServiceCenters = loadable(
  () => import("../pages/serviceCenters/ServiceCenters"),
  fallback
);

export const ServiceCentersFilters = loadable(
  () => import("../pages/serviceCenters/ServiceCentersFilters"),
  fallback
);
export const SearchRenderer = loadable(
  () => import("../pages/search/SearchRenderer"),
  fallback
);

export const Trends = loadable(
  () => import("../pages/trends/Trends/TrendsPage"),
  fallback
);

export const SingleTrend = loadable(
  () => import("../pages/trends/SingleTrend/SingleTrendPage")
);

export const InpectionPage = loadable(
  () => import("../pages/InpectionViewPage/InpectionViewPage"),
  fallback
);

export const Moyasar = loadable(
  () => import("../pages/checkout/Moyasar/Moyasar.jsx"),
  fallback
);

export const CheckoutRenderer = loadable(
  () => import("../pages/checkout/CheckoutRenderer/CheckoutRenderer.jsx"),
  fallback
);

export const MoyasarPaymentProcessing = loadable(
  () => import("../pages/checkout/Moyasar/MoyasarPaymentProcessing.jsx"),
  fallback
);
export const Cities = loadable(
  () => import("../pages/Cities/Cities/CitiesPage"),
  fallback
);

export const SingleCity = loadable(
  () => import("../pages/Cities/SingleCity/SingleCityPage")
);

export const Recommendations = loadable(
  () => import("../pages/Recommendations/Recommendations.jsx")
);

export const Emkan = loadable(() => import("../pages/Emkan/Emkan.jsx"));
export const EmkanStatus = loadable(
  () => import("../pages/Emkan/EmkanStatus.jsx")
);

export const checkoutFailed = loadable(
  () => import("../pages/checkout/checkoutFailed.jsx")
);

export const TrackingFinanceStepDetails = loadable(
  () =>
    import(
      "../pages/tracking/trackingFinance/TrackingFinanceStepDetails/TrackingFinanceStepDetails"
    ),
  fallback
);

export const TrackingFinanceViewAllOfferedDoc = loadable(
  () =>
    import(
      "../pages/tracking/trackingFinance/TrackingFinanceViewAllOfferedDoc/TrackingFinanceViewAllOfferedDoc"
    ),
  fallback
);
export const CarShowRooms = loadable(
  () => import("../pages/CarShowRooms/CarShowRooms"),
  fallback
);

export const Mozon = loadable(
  () => import("../pages/checkout/Mozon/Mozon"),
  fallback
);

export const MozonThanksPage = loadable(
  () => import("../pages/checkout/Mozon/MozonThanksPage/MozonThanksPage"),
  fallback
);
