import fetcher from "../../common/axiosInstance";
import {
  UNBXD_Filters,
  UNBXD_POSTS,
  UNBXD_SERVICES_POSTS,
  UNBXD_SELECTED_Filters,
  UNBXD_USPS,
  UNBXD_META_DATA,
  UNBXD_MARKETING_BANNER,
  UNBXD_DEAL,
  UNBXD_LOADING,
  SET_PREV_URL,
  SIMILAR_ITEMS,
  SET_UUID,
  UNBXD_LOAD_MORE,
  IS_UNBXD_NEW_USER,
  UNBXD_SPECS,
  GET_FILTERS_SSR
} from "../types";
import { apiUrl, serverUrl } from "@globalConfig";
import {
  isRangeFilter,
  mapFacets,
  mapSelected,
} from "@/components/SearchComponent/UnbxdComponents/UnbxdAsideFilters/utils";
import cookieClient from "react-cookies";
import { trackEvents } from "@/common/events/events";
import { extractHashToObject } from "@/utils/GlobalJavascriptFunction";
import { api__doSearch } from "@/apis/search/search";
import { ISearchResults } from "../../../types/models/search";

export const loading = (status) => {
  return {
    type: UNBXD_LOADING,
    payload: status,
  };
};

export const updateLoadMoreClicked = (val) => {
  return {
    type: UNBXD_LOAD_MORE,
    payload: val,
  };
};

export const setPreUrl = (url) => {
  return {
    type: SET_PREV_URL,
    payload: url,
  };
};

export const setUUID = (val) => {
  return {
    type: SET_UUID,
    payload: val,
  };
};

export const getSearch =
  ({ filters, link, page, sort, cb, url, req, size, search, hash }) =>
    async (dispatch) => {
      delete filters.sort;
      if (filters?.is_new && typeof filters?.is_new === "string") {
        filters.is_new =
          filters.is_new === "true" || filters.is_new === "1" ? 1 : 0;
      }

      if (filters?.odometer_to && typeof filters?.odometer_to === "string") {
        filters.odometer_to = filters.odometer_to.replaceAll(",", "");
      }

      if (filters?.odometer_from && typeof filters?.odometer_from === "string") {
        filters.odometer_from = filters.odometer_from.replaceAll(",", "");
      }

      filters.text = filters.text || "";
      const data = {
        filters,
        link,
        page,
        sort: sort || "",
        size,
        new_path: !!(link?.includes("/autos") || link?.includes("/years")),
      };

      dispatch(loading(true));

      const searchResults = await api__doSearch(req, page, data, ["usps", "meta_tags"]);

      dispatch({
        type: UNBXD_POSTS,
        payload: { products: searchResults.products, page },
      });

      dispatch({
        type: UNBXD_Filters,
        payload: mapFacets(searchResults.facets),
      });

      dispatch({
        type: UNBXD_SELECTED_Filters,
        payload: mapSelected(searchResults.selected),
      });

      dispatch({
        type: UNBXD_USPS,
        payload: searchResults.usps,
      });

      dispatch({
        type: SIMILAR_ITEMS,
        payload: searchResults.similar_items,
      });

      dispatch({
        type: UNBXD_META_DATA,
        payload: {
          seoData: searchResults.seo_data,
          totalPages: searchResults.total_pages,
          title: searchResults.title,
          productsCount: searchResults.products_count,
          category_path: searchResults.category_path,
          request_id: searchResults.request_id,
          metaTags: searchResults.meta_tags,
        },
      });

      dispatch({
        type: UNBXD_MARKETING_BANNER,
        payload: {
          desktop: searchResults.marketing_banner_desktop,
          mobile: searchResults.marketing_banner_mobile,
        },
      });

      dispatch({
        type: UNBXD_DEAL,
        payload: searchResults.deal,
      });

      dispatch({
        type: UNBXD_SPECS,
        payload: searchResults.specs,
      });
      if (!req && searchResults.page > 1) {
        const normalizedSearchQuery = search.replace(/([&?])page=\d+/g, "");

        window.history.replaceState(
          null,
          "",
          `${url + normalizedSearchQuery}${normalizedSearchQuery ? "&" : "?"
          }page=${searchResults.page}${hash ? hash : ""}`
        );
      }

      if (__IS_CSR__) {
        trackSearchEvents(filters, searchResults, dispatch);
      }


      if (!link) {
        // need to check after testing the browse search
        dispatch(
          unbxdAnalytics({
            action: "search_impression",
            query: filters.text || "",
            url: serverUrl + url + search,
            requestId: searchResults?.request_id || "N.A",
            pids_list: (searchResults?.products || []).map(
              (product) => product.uniqueId
            ),
          })
        );
      }

      if (link && typeof window !== "undefined") {
        dispatch(
          unbxdAnalytics({
            action: "browse_impression",
            url: serverUrl + url + search,
            requestId: searchResults?.request_id || "N.A",
            page: searchResults?.category_path || "",
            page_type: "BOOLEAN",
            pids_list: (searchResults?.products || []).map(
              (product) => product.uniqueId
            ),
          })
        );
      }

      if (cb) cb();

      dispatch(loading(false));
    };


export const getFiltersSSR =
  ({ req, id }) =>
    async (dispatch) => {
      dispatch(loading(true));
      const res = await fetcher(req).post(`/search/deal?id=${id}`);
      dispatch({
        type: GET_FILTERS_SSR,
        payload: res.data.data,
      });

      dispatch(loading(false));
    };


export const getServicesPosts =
  ({ filters, link, page, sort, cb, req, size }) =>
    async (dispatch) => {
      dispatch(loading(true));
      const data = {
        filters,
        link,
        page,
        sort: sort || "",
        size,
        new_path: false,
      };

      const searchResults = await api__doSearch(req, page, data);

      dispatch({
        type: UNBXD_SERVICES_POSTS,
        payload: { products: searchResults.products, page },
      });
      if (cb) cb();

      dispatch(loading(false));
    };


export const cleanUpServicesPosts = () => async (dispatch) => {
  dispatch({
    type: UNBXD_SERVICES_POSTS,
    payload: { products: [], page: 1 },
  });
};

export const getUnbxdSuggestions = (lang, searchTerm, cb) => async () => {
  const url = (process.env.RAZZLE_ENV_UNBXD_AUTOSUGGEST_URL || "")
    .replace("{lang}", lang)
    .replace("{apiUrl}", apiUrl);
  const normalizedSearchTerm = searchTerm || "";
  const res = await fetcher().get(`${url}${normalizedSearchTerm}`);

  if (res?.status === 200) return cb(res.data?.data?.products || []);

  cb([]);
};

export const getUnbxdRecommendations = (data) => async (dispatch) => {
  // turn off recommendations for now
  return;
};

export const unbxdAnalytics = (data) => async (dispatch, getState) => {
  if (data.action !== "visitor" && !data.requestId) return;
  const { uid, isNew } = getOrCreateUnbxdUserId(dispatch, getState);

  data.uid = uid;
  data.visit_type = isNew ? "first_time" : "repeat";

  let visitId = cookieClient.load("visitId");

  if (!visitId) {
    visitId = `visitId-${new Date().getTime()}-${Math.floor(
      Math.random() * 100000
    )}`;

    // Create a date object for the current time
    const currentDate = new Date();

    // Set the cookie to expire 30 minutes from now
    const expireTime = new Date(currentDate.getTime() + 30 * 60000); // 60000 milliseconds in a minute

    // Save the cookie with the expiration time
    cookieClient.save("visitId", visitId, { path: "/", expires: expireTime });
  }
  if (!__IS_SSR__) {
    fetcher(null, true)
      .post(`/search/track`, {
        ...data,
        visitId,
        referrer: getReferralURL(),
      })
      .catch((e) => {
        return Promise.resolve("Ignore search analytics error");
      });
  }
};

export function getReferralURL() {
  // Check if running in a browser environment
  if (typeof window !== "undefined" && window.dataLayer) {
    // Filter the dataLayer array for 'gtm.historyChange' events

    const filtered = window.dataLayer.filter(
      (s) => s.event === "gtm.historyChange"
    );

    if (filtered.length > 0) {
      // Access the last element's 'gtm.oldUrl' property and decode it
      const lastUrl = decodeURI(
        filtered[filtered.length - 1]?.["gtm.oldUrl"] ?? ""
      );
      return lastUrl;
    }
  }

  // Fall back to document.referrer if available
  if (typeof document !== "undefined" && document.referrer) {
    return document.referrer;
  }

  // Return empty string if no referrer is found
  return "";
}

export const getOrCreateUnbxdUserId = (dispatch, getState) => {
  const UUID = cookieClient.load("UUID");
  const newUUID = `uid-${new Date().getTime()}-${Math.floor(
    Math.random() * 100000
  )}`;

  let isUnbxdNewUser = false;

  if (!UUID) {
    isUnbxdNewUser = true;
    dispatch({
      type: IS_UNBXD_NEW_USER,
      payload: true,
    });

    cookieClient.save("UUID", newUUID, {
      path: "/",
      maxAge: 3600 * 24 * 365 * 10,
      sameSite: true,
    });
  }

  return {
    uid: UUID || newUUID,
    isNew: !UUID || isUnbxdNewUser || getState().unbxd.isUnbxdNewUser,
  };
};

const trackSearchEvents = (filters, searchResults: ISearchResults, dispatch) => {
  const url = window.location.href;
  const hashObject = extractHashToObject(url);
  const isBrowseSearch = hashObject.hasOwnProperty("referral_location");
  const isTextSearch = filters.text;
  if (isTextSearch) {
    const isQuerySuggest = window.searchSuggestion || null;

    trackEvents("search", {
      search_query: filters.text,
      results_count: searchResults.products_count || 0,
      interaction_type: isQuerySuggest ? "query suggest" : "user input",
      page_number: searchResults.page,
    });

    return;
  }

  if (isBrowseSearch) {
    trackEvents("browse_cars", {
      ...hashObject,
      results_count: searchResults.products_count || 0,
      page_number: searchResults.page,
    });
    return;
  }

  let filterValue;

  if (window?.lastClickedFilter?.length) {
    filterValue = isRangeFilter(lastClickedFilter[0]?.filterField)
      ? `from ${window.lastClickedFilter[0].range_values.from}, to ${window.lastClickedFilter[0].range_values.to}`
      : window?.lastClickedFilter.map((item) => item.name_en);
  }

  trackEvents("filter_search", {
    filter_name: window?.lastClickedFilter?.length
      ? window?.lastClickedFilter[0]?.filterField
      : null,
    filter_value: filterValue ? filterValue : null,
    results_count: searchResults?.products_count || 0,
    page_number: searchResults.page,
  });

  dispatch(
    unbxdAnalytics({
      action: "filter_search",
      filter_name: window?.lastClickedFilter?.length
        ? window?.lastClickedFilter[0]?.filterField
        : null,
      filter_value: filterValue ? filterValue : null,
      results_count: searchResults?.products_count || 0,
      page_number: searchResults?.page,
      requestId: searchResults?.request_id || "N.A",
    })
  );

  window.lastClickedFilter = null;
};
