export const serverUrl = process.env.RAZZLE_SERVER_URL;
export const apiUrl = process.env.RAZZLE_API_ENDPOINT;
export const search_apiUrl = process.env.RAZZLE_API_SEARCH;
export const CLIENT_SECRET = process.env.RAZZLE_CLIENT_SECRET;
export const SELECTED_ENV = process.env.RAZZLE_ENV;
export const CDN_DOMAIN = process.env.RAZZLE_CDN_LINK;
export const CDN_LINK =
  process.env.NODE_ENV !== "production" ? "" : `${CDN_DOMAIN}/${SELECTED_ENV}`;
export const CHECKOUT_VISA_PUBLIC_KEY =
  process.env.RAZZLE_CHECKOUT_PUBLIC_KEY_VISA;
export const CHECKOUT_MADA_PUBLIC_KEY =
  process.env.RAZZLE_CHECKOUT_PUBLIC_KEY_MADA;
export const ARBNOON_AMOUNT = 150;
export const FINANCE_APP_AMOUNT = 10;
export const SADAD_MAX_AMOUNT = 250000;
export const SENTRY_DSN = process.env.RAZZLE_SENTRY_SERVER_DSN;
export const SENTRY_CLIENT_DSN = process.env.RAZZLE_SENTRY_CLIENT_DSN;
export const BUILD_VERSION = process.env.RAZZLE_BUILD_VERSION;

export const UNBXD_RECOMMENDATIONS_COOKIE_KEY =
  process.env.RAZZLE_UNBXD_RECOMMENDATIONS_COOKIE_KEY || "_vis_opt_exp_5_combi";
export const CRYPTO_JS_SECRET_KEY =
  process.env.RAZZLE_FRONT_END_CRYPTO_JS_SECRET_KEY;
export const SENTRY_TRACE_SAMPLE_RATE = Number(
  process.env.RAZZLE_SENTRY_TRACE_SAMPLE_RATE || "0.05"
);
export const SIGN_UP_A_B_TESTING = process.env.RAZZLE_SIGN_UP_A_B_TESTING;
export const I18N_CDN = process.env.RAZZLE_I18N_CDN || `https://testcdn-frontend-r2.syarah.com/{env}/translations/{lang}/{namespace}.json`;
export const IS_MOZN = process.env.RAZZLE_IS_MOZN === "true" || false;
export const GROWTHBOOK_CLIENT_KEY = process.env.RAZZLE_GROWTHBOOK_CLIENT_KEY ;
