import {useEffect, useState} from "react";

const DEFAULT_TIMEOUT = 6000;
const useDelayRender = (timeout: number = DEFAULT_TIMEOUT) => {
    const [isDelayRender, setIsDelayRender] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsDelayRender(true);
        }, timeout);
    }, []);

    return isDelayRender;
};

export default useDelayRender;
