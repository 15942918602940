import * as asyncPages from "./asyncPages";
// @ts-ignore
import { TypeSSRData } from "./ssrDataTypes";

export default function extractedRoutes() {
  //import * as ssrData from "../ssrData";
  // load SSR data only when on server
  // mock ssrData for client
  let ssrData = {} as TypeSSRData;
  let testRoutes = () => [];
  //__IS_SSR__ is a global variable defined by Webpack
  if (__IS_SSR__) {
    ssrData = require("./ssrData");
  }
  if (__ENV__ === "dev") {
    testRoutes = require("./testRoutes");
  }

  const routes = [
    ...testRoutes(),
    {
      path: ["/", "/home/new-cars"],
      component: asyncPages.Home,
      getInitialData: ssrData.homeInitialData,
      getHeaderTags: ssrData.homeHeaderTags,
      name: "Home",
      exact: true,
    },
    {
      path: [
        "/autos/*",
        "/autos/:make",
        "/autos/:make/:model",
        "/autos/:make/:model/:year",
        "/years",
        "/years/:year",
        "/years/:year/:make",
      ],
      component: asyncPages.SearchRenderer,
      getInitialData: ssrData.newSearchInitialData,
      getHeaderTags: ssrData.searchHeaderTags,
      name: "Search",
      exact: false,
    },

    {
      path: "/auth-redirect/:token/*",
      component: asyncPages.AuthRedirect,
      name: "Auth",
    },
    {
      path: "/user/login",
      component: asyncPages.UserLogin,
      name: "login",
    },
    {
      path: [
        "/user/signup/:page/:categoryId/:event/:postId/:isOnline/:carCondition",
        "/user/signup/:page/:categoryId/:event/:postId",
        "/user/signup/:page/:categoryId/:event",
        "/user/signup/*",
      ],
      component: asyncPages.SignUp,
      name: "signUp",
    },
    {
      path: [
        "/terms",
        "/terms-landing",
        "/terms-landing/*",
        "/car-purchase-terms-conditions",
        "/car-purchase-terms-conditions/*",
      ],
      getFullPageData: ssrData.termsFullPageData,
      component: asyncPages.CarPurchaseTermsConditions,
      getHeaderTags: ssrData.termsLandingHeaderTags,
      name: "terms landing",
      exact: true,
    },
    {
      path: ["/car/:id", "/car/:id/*", "/cardetail/:id", "/cardetail/:id/*"],
      component: asyncPages.PostView,
      getInitialData: ssrData.postInitialData,
      getHeaderTags: ssrData.postHeaderTags,
      name: "Post View",
      exact: true,
    },
    {
      path: ["/car/similar-cars/:postId", "/car/similar-cars/:postId/*"],
      component: asyncPages.SimilarPosts,
      // getInitialData: ssrData.postInitialData,
      // getHeaderTags: ssrData.postHeaderTags,
      name: "SimilarPosts Posts",
      exact: true,
    },
    {
      path: ["/help"],
      component: asyncPages.HelpPage,
      getHeaderTags: ssrData.helpHeaderTags,
      name: "Help",
      exact: true,
    },
    {
      path: ["/تسويق_بالعمولة", "/network"],
      component: asyncPages.Affiliate,
      name: "Affiliate",
      exact: true,
    },
    {
      path: ["/faq"],
      component: asyncPages.Faq,
      getFullPageData: ssrData.faqFullPageData,
      getHeaderTags: ssrData.faqsHeaderTags,
      name: "Faq",
      exact: true,
    },
    {
      path: ["/feedback"],
      component: asyncPages.Reviews,
      getInitialData: ssrData.reviewsInitialData,
      getHeaderTags: ssrData.reviewsHeaderTags,
      name: "Reviews",
      exact: true,
    },
    {
      path: ["/privacy-policy", "/privacy-policy/*"],
      component: asyncPages.CarPurchasePrivacyPolicy,
      getHeaderTags: ssrData.PrivacyPolicyHeaderTags,
      name: "privacy policy",
      exact: true,
    },
    {
      path: ["/about-us"],
      component: asyncPages.AboutUs,
      getHeaderTags: ssrData.aboutUsHeaderTags,
      name: "About Us",
      exact: true,
    },
    {
      path: ["/about-us/services"],
      component: asyncPages.Services,
      getHeaderTags: ssrData.servicesHeaderTags,
      name: "Services",
      exact: true,
    },
    {
      path: ["/offerspage"],
      component: asyncPages.MobileOffers,
      getFullPageData: ssrData.offersFullPageData,
      getHeaderTags: ssrData.offersHeaderTags,
      name: "Mobile Offers",
      exact: true,
    },
    {
      path: ["/contact-us"],
      component: asyncPages.Contact,
      getHeaderTags: ssrData.contactHeaderTags,
      name: "contact",
    },
    {
      path: ["/checkout/shop-apply", "/checkout/shop-apply/*"],
      component: asyncPages.StepOne,
      name: "check Out step one",
      exact: true,
    },
    {
      path: ["/checkout/shop-payment/:postId/*"],
      component: asyncPages.CheckoutRenderer,
      name: "check Out payment",
      exact: true,
    },
    {
      path: ["/payedsuccess", "/payedsuccess/*"],
      component: asyncPages.Checkoutsuccess,
      name: "checkout success page",
      exact: true,
    },
    {
      path: ["/payedfailed/:postId", "/payedfailed/:postId/*"],
      component: asyncPages.checkoutFailed,
      name: "checkout failed page",
      exact: true,
    },
    {
      path: ["/tracking-landing"],
      component: asyncPages.TrackingLanding,
      name: "tracking",
      exact: true,
    },

    {
      path: ["/tracking-finance-orders"],
      component: asyncPages.TrackingFinanceOrders,
      name: "tracking finance",
      exact: true,
    },
    {
      path: ["/tracking-checkout-orders"],
      component: asyncPages.TrackingCheckoutOrders,
      name: "tracking checkout",
      exact: true,
    },
    {
      path: ["/tracking-finance-details", "/tracking-finance-details/:orderId"],
      component: asyncPages.TrackingFinanceDetails,
      name: "tracking finance details",
      exact: true,
    },
    {
      path: [
        "/tracking-checkout-details",
        "/tracking-checkout-details/:orderId",
      ],
      component: asyncPages.TrackingCheckoutDetails,
      name: "tracking checkout details",
      exact: true,
    },
    {
      path: [
        "/tracking-checkout-details",
        "/tracking-checkout-details/:orderId/:stepNum",
      ],
      component: asyncPages.TrackingCheckoutStepDetails,
      name: "tracking checkout step details",
      exact: true,
    },
    {
      path: [
        "/tracking-finance-details",
        "/tracking-finance-details/:orderId/:stepNum",
      ],
      component: asyncPages.TrackingFinanceStepDetails,
      name: "tracking finance step details",
      exact: true,
    },
    {
      path: [
        "/tracking-checkout-details",
        "/tracking-checkout-details/view-all-offered-doc/:orderId",
      ],
      component: asyncPages.TrackingCheckoutViewAllOfferedDoc,
      name: "tracking checkout step details",
      exact: true,
    },
    {
      path: [
        "/tracking-finance-details",
        "/tracking-finance-details/view-all-offered-doc/:orderId",
      ],
      component: asyncPages.TrackingFinanceViewAllOfferedDoc,
      name: "tracking finance step details",
      exact: true,
    },
    {
      path: [
        "/car-info",
        "/car-info/:orderId",
        "/car-info/:orderId/:isFinance",
        "/car-info/*",
      ],
      component: asyncPages.CarInfo,
      name: "car info",
      exact: true,
    },
    {
      path: ["/referral/:id/:phoneNumber", "/referral/:id/*"],
      component: asyncPages.Referral,
      name: "referral",
      exact: true,
    },
    {
      path: ["/referral-list/:id/:listType"],
      component: asyncPages.ReferralList,
      name: "referral list",
    },
    {
      path: ["/تقسيط-سيارات"],
      component: asyncPages.Finance,
      getHeaderTags: ssrData.financeHeaderTags,
      name: "Finance",
    },
    {
      path: ["/offline-payment/:token", "/offline-payment/:token/*"],
      component: asyncPages.Payment,
      name: "payment",
      exact: true,
    },
    {
      path: [
        "/orders/customer-survey",
        "/orders/customer-survey/:orderId/*",
        "/orders/customer-cancelled-survey",
        "/orders/customer-cancelled-survey/:orderId/*",
      ],
      component: asyncPages.Survey,
      name: "NPS Survey",
      exact: true,
    },
    {
      path: ["/remaining-payment/:id"],
      component: asyncPages.RemainingPayment,
      name: "remaining payment",
      exact: true,
    },
    {
      path: ["/finance-remaining-payment/:id"],
      component: asyncPages.FinanceRemainingPayment,
      name: "finance remaining payment",
      exact: true,
    },
    {
      path: "/page404",
      component: asyncPages.page404,
      name: "page404",
      exact: true,
    },
    {
      path: ["/finance-apply/:id"],
      component: asyncPages.FinanceFormPage,
      getInitialData: ssrData.financeFormInitialData,
      name: "finance",
      exact: true,
    },

    {
      path: ["/finance-apply-free/:id"],
      component: asyncPages.FinanceFormPageFree,
      getInitialData: ssrData.financeFormInitialData,
      name: "finance",
      exact: true,
    },

    {
      path: [
        "/finance/shop-payment/:postId",
        "/finance/shop-payment/:postId/*",
      ],
      component: asyncPages.FinancePayment,
      name: "check Out Finance payment",
      exact: true,
    },

    {
      path: ["/process-payment", "/process-payment/*"],
      component: asyncPages.PaymentProccessing,
      name: "check Out Finance payment proccessing",
      exact: true,
    },
    {
      path: ["/process-payment-cash", "/process-payment-cash/*"],
      component: asyncPages.PaymentProccessingCash,
      name: "check Out Cash payment proccessing",
      exact: true,
    },
    {
      path: [
        "/process-payment-moyasar-callback",
        "/process-payment-moyasar-callback/*",
      ],
      component: asyncPages.MoyasarPaymentProcessing,
      name: "payment Processing",
      exact: true,
    },
    {
      path: ["/finance/payedsuccess", "/finance/payedsuccess/*"],
      component: asyncPages.FinanceCheckoutsuccess,
      name: "check Out Finance payment success",
      exact: true,
    },
    {
      path: ["/sell-tradein-your-car/*", "/بدل-بيع-سيارتك/*", "/sell-your-car"],
      component: asyncPages.SellUs,
      getInitialData: ssrData.sellusInitialData,
      getHeaderTags: ssrData.sellusHeaderTags,
      name: "sell us your car",
      exact: true,
    },
    {
      path: ["/finance/rejected", "/finance/rejected/*"],
      component: asyncPages.FinanceRejectedPage,
      name: "Finance rejected",
      exact: true,
    },
    {
      path: ["/user/account"],
      component: asyncPages.UserAccount,
      name: "user account",
      exact: true,
    },
    {
      path: ["/user/favorite"],
      component: asyncPages.UserFavorite,
      name: "user favorite",
      exact: true,
    },
    {
      path: ["/user/account/my-info"],
      component: asyncPages.UserInfo,
      name: "user info",
      exact: true,
    },
    {
      path: ["/user/account/my-info/edit-name"],
      component: asyncPages.UserEditName,
      name: "user edit name",
      exact: true,
    },
    {
      path: ["/user/account/my-info/edit-mobile"],
      component: asyncPages.UserEditMobile,
      name: "user edit mobile",
      exact: true,
    },
    {
      path: ["/pre-checkout", "/pre-checkout/:postId"],
      component: asyncPages.PreCheckout,
      name: "pre checkout",
      exact: true,
    },
    {
      path: ["/finance-optional-refund/:orderId"],
      component: asyncPages.FinanceOptionalRefund,
      // getHeaderTags:
      name: "Finance optional refund",
    },
    {
      path: ["/filters", "/filters/*", "/deal/:dealId"],
      component: asyncPages.SearchRenderer,
      getInitialData: ssrData.filtersPageInitialData,
      getHeaderTags: ssrData.searchHeaderTags,
      name: "filters",
      exact: true,
    },
    {
      path: ["/service-centers"],
      component: asyncPages.ServiceCenters,
      getHeaderTags: ssrData.serviceSentersHeaderTags,
      name: "ServiceCenters",
      exact: true,
    },
    {
      path: ["/service-centers/filters"],
      component: asyncPages.ServiceCentersFilters,
      name: "ServiceCenters",
      exact: true,
    },
    {
      path: ["/healthcheck"],
      component: asyncPages.healthcheck,
      name: "healthCheck",
    },
    {
      path: ["/trend/:permalink"],
      component: asyncPages.SingleTrend,
      getInitialData: ssrData.SingleTrendInitialData,
      getHeaderTags: ssrData.SingleTrendHeaderTags,
      name: "Trend Permalink",
    },
    {
      path: ["/trend", "/trend?page=:any"],
      component: asyncPages.Trends,
      getInitialData: ssrData.TrendsInitialData,
      getHeaderTags: ssrData.TrendsHeaderTags,
      name: "Trend",
    },
    {
      path: ["/city/:cityName"],
      component: asyncPages.SingleCity,
      getInitialData: ssrData.SingleCityInitialData,
      getHeaderTags: ssrData.SingleCityHeaderTags,
      name: "Single city",
    },
    {
      path: ["/city"],
      component: asyncPages.Cities,
      getInitialData: ssrData.CitiesInitialData,
      getHeaderTags: ssrData.CitiesHeaderTags,
      name: "Cities",
    },
    {
      path: ["/report/:id", "/report/:id/*"],
      component: asyncPages.InpectionPage,
      getInitialData: ssrData.InpectionInitialData,
      getHeaderTags: ssrData.InpectionHeaderTags,
      name: "Post View Inpection",
      exact: true,
    },
    {
      path: ["/recommendations"],
      component: asyncPages.Recommendations,
      name: "Recommendations",
    },
    {
      path: ["/moyasar/*"],
      component: asyncPages.Moyasar,
      name: "Moyasar",
      exact: true,
    },
    {
      path: ["/finance/emkan/*"],
      component: asyncPages.Emkan,
      name: "Emkan",
      exact: true,
    },
    {
      path: ["/finance/emkan/status/*"],
      component: asyncPages.EmkanStatus,
      name: "Emkan Status",
      exact: true,
    },
    {
      path: ["/car-showrooms"],
      component: asyncPages.CarShowRooms,
      // getInitialData: ssrData.CarRoomsInitialData,
      getFullPageData: ssrData.carRoomsFullPageData,
      getHeaderTags: ssrData.carRoomsHeaderTags,
      name: "Car Show Rooms",
      exact: true,
    },
    {
      path: ["/mozon-apply/:postId"],
      component: asyncPages.Mozon,
      name: "Mozon",
      exact: true,
    },
    {
      path: ["/mozon-thanks/:postId"],
      component: asyncPages.MozonThanksPage,
      name: "Mozon",
      exact: true,
    },
  ];

  let paths = [];
  routes.forEach((element) => {
    if (Array.isArray(element.path)) {
      element.path.forEach((path) => {
        paths.push({ ...element, path });
        paths.push({ ...element, path: "/en" + path });
      });
    } else {
      paths.push(element);
      paths.push({ ...element, path: "/en" + element.path });
    }
  });
  return paths;
}
