import { GET_CITIES, GET_CITY_DETAILS } from "../types";

const initialState = {
  citiesData: {},
  cityDetails: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CITIES:
      return {
        ...state,
        citiesData: payload,
      };
    case GET_CITY_DETAILS:
      return {
        ...state,
        cityDetails: payload,
      };

    default:
      return state;
  }
};
