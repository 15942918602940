import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import homeReducer from "./homeReducer";
import searchReducer from "./searchReducer";
import postReducer from "./postReducer";
import userReducer from "./userReducer";
import feedbackReducer from "./feedbackReducer";
import checkoutReducer from "./checkoutReducer";
import trackingReducer from "./trackingReducer";
import financeReducer from "./financeReducer";
import surveyReducer from "./surveyReducer";
import trackingFinanceReducer from "./trackingFinanceReducer";
import newSearch from "./newSearchReducer";
import trackingCheckoutReducer from "./trackingCheckoutReducer";
import serviceCentersReducer from "./serviceCentersReducer";
import unbxdReducer from "./unbxdReducer";
import trendsReducer from "./trendsReducer";
import citiesReducer from "./citiesReducer";
import inpectionViewReducer from "./inpectionViewReducer";
import trackingStepReducer from "./trackingStepReducer";

const reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  home: homeReducer,
  search: searchReducer,
  post: postReducer,
  user: userReducer,
  feedback: feedbackReducer,
  checkout: checkoutReducer,
  tracking: trackingReducer,
  trackingCheckout: trackingCheckoutReducer,
  trackingFinance: trackingFinanceReducer,
  trackingStep: trackingStepReducer,
  finance: financeReducer,
  survey: surveyReducer,
  newSearch: newSearch,
  serviceCenters: serviceCentersReducer,
  unbxd: unbxdReducer,
  trends: trendsReducer,
  cities: citiesReducer,
  inpectionViewReducer: inpectionViewReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
