export default [
  "web_common",
  "web_aside_menu",
  "web_footer",
  "web_header",
  "web_home",
  "web_search",
  "web_post_view",
  "web_checkout_cash",
  "web_checkout_finance",
  "web_tracking",
  "web_tracking_finance",
  "web_sell_trade",
  "web_survey",
  "web_limited_availability",
  "web_edit_post",
  "web_user_account",
  "web_favorite",
  "web_auth",
  "web_pre_checkout",
  "web_services",
  "web_about_us",
  "web_finance_order_refund",
  "web_service_centers",
  "web_trend",
  "web_city",
  "web_offers",
  "web_404",
  "web_car_show_rooms",
  "web_mozon"
] as const;